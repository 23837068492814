export default function TechSVG () {
  return (
    <div className="flex items-stretch py-4">
        <svg viewBox="0 0 260 260" fill="none" className="hover:scale-110">
          <path
            d="M93.9281 166.034L85.9067 76H174.093L166.072 165.985L129.927 176"
            fill="#E44D26"
          />
          <path
            d="M130 168.344V83.3901H166.047L159.168 160.201"
            fill="#F16529"
          />
          <path
            d="M102.29 94.4009H130V105.436H114.395L115.416 116.739H130V127.75H105.304L102.29 94.4009ZM105.79 133.292H116.874L117.652 142.116L130 145.422V156.943L107.346 150.624"
            fill="#EBEBEB"
          />
          <path
            d="M157.613 94.4009H129.951V105.436H156.592L157.613 94.4009ZM155.595 116.739H129.951V127.775H143.563L142.275 142.116L129.951 145.422V156.895L152.557 150.624"
            fill="white"
          />
        </svg>
        <svg viewBox="0 0 260 260" fill="none" className="hover:scale-110">
          <path
            d="M175.192 76L167.159 165.99L131.051 176L95.0415 166.004L87.017 76H175.192Z"
            fill="#264DE4"
          />
          <path
            d="M160.282 160.259L167.147 83.3591H131.104V168.348L160.282 160.259Z"
            fill="#2965F1"
          />
          <path
            d="M105.413 116.74L106.402 127.779H131.105V116.74H105.413Z"
            fill="#EBEBEB"
          />
          <path
            d="M131.105 94.3977H131.066H103.425L104.428 105.436H131.105V94.3977Z"
            fill="#EBEBEB"
          />
          <path
            d="M131.105 156.893V145.408L131.056 145.421L118.762 142.102L117.977 133.298H112.003H106.896L108.442 150.63L131.054 156.907L131.105 156.893Z"
            fill="#EBEBEB"
          />
          <path
            d="M144.659 127.779L143.378 142.095L131.066 145.418V156.902L153.696 150.63L153.862 148.765L156.456 119.704L156.725 116.74L158.718 94.3977H131.066V105.436H146.621L145.616 116.74H131.066V127.779H144.659Z"
            fill="white"
          />
        </svg>
        <svg viewBox="0 0 260 260" fill="none" className="hover:scale-110">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M101.427 85.7638C95.7212 85.7638 91.4991 90.7579 91.6882 96.174C91.8696 101.377 91.6339 108.116 89.9374 113.612C88.2349 119.123 85.3575 122.616 80.6567 123.064V128.125C85.3575 128.574 88.2349 132.065 89.9372 137.576C91.6339 143.072 91.8694 149.811 91.688 155.015C91.4989 160.43 95.721 165.425 101.428 165.425H159.894C165.6 165.425 169.821 160.431 169.632 155.015C169.451 149.811 169.686 143.072 171.383 137.576C173.085 132.065 175.956 128.573 180.657 128.125V123.064C175.956 122.615 173.086 119.124 171.383 113.612C169.686 108.117 169.451 101.377 169.632 96.174C169.821 90.7587 165.6 85.7638 159.894 85.7638H101.426H101.427ZM148.453 134.8C148.453 142.258 142.891 146.781 133.659 146.781H117.945C117.495 146.781 117.064 146.602 116.746 146.284C116.428 145.966 116.25 145.535 116.25 145.086V106.103C116.25 105.653 116.428 105.222 116.746 104.904C117.064 104.587 117.495 104.408 117.945 104.408H133.57C141.267 104.408 146.319 108.577 146.319 114.979C146.319 119.472 142.921 123.495 138.591 124.199V124.434C144.485 125.08 148.453 129.162 148.453 134.8ZM131.946 109.781H122.986V122.437H130.532C136.366 122.437 139.583 120.088 139.583 115.889C139.583 111.954 136.817 109.781 131.946 109.781ZM122.986 127.459V141.406H132.275C138.35 141.406 141.567 138.969 141.567 134.388C141.567 129.808 138.259 127.458 131.885 127.458H122.986V127.459Z"
            fill="#7952B3"
          />
        </svg>
        <svg viewBox="0 0 260 260" fill="none" className="hover:scale-110">
          <path
            d="M130.828 96.4933C117.495 96.4933 109.161 103.158 105.828 116.487C110.828 109.822 116.662 107.324 123.328 108.989C127.132 109.94 129.851 112.699 132.86 115.753C137.762 120.727 143.436 126.484 155.829 126.484C169.161 126.484 177.496 119.819 180.828 106.489C175.829 113.154 169.996 115.654 163.328 113.989C159.525 113.038 156.807 110.278 153.797 107.225C148.895 102.25 143.221 96.4933 130.828 96.4933ZM105.828 126.484C92.4952 126.484 84.1609 133.149 80.8284 146.479C85.8277 139.814 91.6612 137.315 98.3287 138.982C102.131 139.933 104.85 142.692 107.86 145.744C112.762 150.718 118.436 156.477 130.828 156.477C144.162 156.477 152.495 149.812 155.829 136.482C150.828 143.147 144.995 145.647 138.329 143.98C134.525 143.031 131.806 140.27 128.796 137.217C123.895 132.243 118.221 126.484 105.828 126.484Z"
            fill="#06B6D4"
          />
        </svg>
        <svg viewBox="0 0 260 260" fill="none" className="hover:scale-110">
          <path
            d="M114.162 176C123.362 176 130.828 168.533 130.828 159.333V142.667H114.162C104.962 142.667 97.4951 150.133 97.4951 159.333C97.4951 168.533 104.962 176 114.162 176Z"
            fill="#0ACF83"
          />
          <path
            d="M97.4951 126C97.4951 116.8 104.962 109.333 114.162 109.333H130.828V142.667H114.162C104.962 142.667 97.4951 135.2 97.4951 126Z"
            fill="#A259FF"
          />
          <path
            d="M97.4951 92.6667C97.4951 83.4667 104.962 76 114.162 76H130.828V109.333H114.162C104.962 109.333 97.4951 101.867 97.4951 92.6667Z"
            fill="#F24E1E"
          />
          <path
            d="M130.828 76H147.495C156.695 76 164.162 83.4667 164.162 92.6667C164.162 101.867 156.695 109.333 147.495 109.333H130.828V76Z"
            fill="#FF7262"
          />
          <path
            d="M164.162 126C164.162 135.2 156.695 142.667 147.495 142.667C138.295 142.667 130.828 135.2 130.828 126C130.828 116.8 138.295 109.333 147.495 109.333C156.695 109.333 164.162 116.8 164.162 126Z"
            fill="#1ABCFE"
          />
        </svg>
        <svg viewBox="0 0 260 260" fill="none" className="hover:scale-110">
          <path
            d="M180.552 75.5942H80.5522V175.594H180.552V75.5942Z"
            fill="#F7DF1E"
          />
          <path
            d="M147.727 153.72C149.741 157.009 152.362 159.426 156.997 159.426C160.89 159.426 163.378 157.48 163.378 154.791C163.378 151.569 160.822 150.428 156.536 148.553L154.187 147.545C147.406 144.656 142.901 141.037 142.901 133.386C142.901 126.339 148.271 120.974 156.663 120.974C162.638 120.974 166.933 123.053 170.028 128.497L162.711 133.196C161.1 130.307 159.362 129.169 156.663 129.169C153.911 129.169 152.166 130.915 152.166 133.196C152.166 136.015 153.912 137.156 157.944 138.902L160.293 139.909C168.278 143.332 172.785 146.823 172.785 154.67C172.785 163.131 166.139 167.766 157.214 167.766C148.487 167.766 142.849 163.607 140.09 158.156L147.727 153.72ZM114.532 154.534C116.008 157.153 117.351 159.367 120.579 159.367C123.666 159.367 125.614 158.159 125.614 153.463V121.51H135.011V153.59C135.011 163.32 129.306 167.748 120.979 167.748C113.455 167.748 109.098 163.855 106.882 159.165L114.532 154.534Z"
            fill="black"
          />
        </svg>
        <svg viewBox="0 0 260 260" fill="none" className="hover:scale-110">
          <path
            d="M131.105 134.411C135.974 134.411 139.921 130.463 139.921 125.594C139.921 120.725 135.974 116.778 131.105 116.778C126.235 116.778 122.288 120.725 122.288 125.594C122.288 130.463 126.235 134.411 131.105 134.411Z"
            fill="#61DAFB"
          />
          <path
            d="M131.104 143.657C157.231 143.657 178.411 135.57 178.411 125.594C178.411 115.619 157.231 107.532 131.104 107.532C104.978 107.532 83.7979 115.619 83.7979 125.594C83.7979 135.57 104.978 143.657 131.104 143.657Z"
            stroke="#61DAFB"
            strokeWidth="5"
          />
          <path
            d="M115.462 134.625C128.525 157.252 146.119 171.551 154.758 166.563C163.397 161.575 159.81 139.189 146.747 116.563C133.684 93.9366 116.09 79.6377 107.451 84.6255C98.8119 89.6133 102.398 111.999 115.462 134.625Z"
            stroke="#61DAFB"
            strokeWidth="5"
          />
          <path
            d="M115.462 116.563C102.399 139.189 98.812 161.575 107.451 166.563C116.09 171.551 133.684 157.252 146.747 134.625C159.81 111.999 163.397 89.6133 154.758 84.6255C146.119 79.6376 128.525 93.9365 115.462 116.563Z"
            stroke="#61DAFB"
            strokeWidth="5"
          />
        </svg>
        <svg viewBox="0 0 260 260" fill="none" className="hover:scale-110">
          <path
            d="M147.46 143.605H138.683L137.949 142.872V122.326C137.949 118.671 136.513 115.837 132.105 115.738C129.836 115.679 127.241 115.738 124.467 115.847L124.051 116.273V124.575V142.862L123.318 143.595H114.541L113.808 142.862V107.764L114.541 107.031H134.294C141.971 107.031 148.193 113.252 148.193 120.929V142.872L147.46 143.605Z"
            fill="white"
          />
          <path
            d="M101.593 163.051H100.384L94.3516 157.018V155.809L103.574 146.587H109.964L110.816 147.439V153.828L101.593 163.051Z"
            fill="#32E6E2"
          />
          <path
            d="M94.3516 94.816V93.6074L100.384 87.5744H101.593L110.816 96.7972V103.187L109.964 104.039H103.574L94.3516 94.816Z"
            fill="#32E6E2"
          />
          <path
            d="M102.841 130.449H67.7331L67 129.716V120.919L67.7331 120.186H102.841L103.574 120.919V129.716L102.841 130.449Z"
            fill="#32E6E2"
          />
          <path
            d="M194.277 130.449H159.169L158.436 129.716V120.919L159.169 120.186H194.277L195.01 120.919V129.716L194.277 130.449Z"
            fill="#32E6E2"
          />
          <path
            d="M125.943 96.0642V69.7331L126.676 69H135.473L136.206 69.7331V96.0642L135.473 96.7972H126.676L125.943 96.0642Z"
            fill="#32E6E2"
          />
          <path
            d="M125.943 180.902V154.571L126.676 153.838H135.473L136.206 154.571V180.902L135.473 181.635H126.676L125.943 180.902Z"
            fill="#32E6E2"
          />
        </svg>
    </div>
  );
}

